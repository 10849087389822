import React, { Component } from 'react'

export default class BackToTop extends Component {
    render() {
        return (
            <a href="#" class="back-to-top">
                <i class="lni-chevron-up"></i>
            </a>
        )
    }
}
