import React from 'react'
export default class Impressum extends React.Component {
  render() {
    return (
      <div>
        <div className="navbar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="/">
                    <img src="./static/assets/images/logo.png" alt="Logo" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section
          style={{
            backgroundColor: '#f5f6f7',
            display: 'flex',
            justifyContent: 'center',
            minHeight: '100vh',
            padding: '100px',
            fontSize: '25px',
            lineHeight: '34px',
          }}
        >
          Voltoo GmbH <br />
          Karlsruherstr. 9<br />
          70771 Leinfelden-Echterdingen <br />
          Deutschland
          <br />
          E-Mail: info@voltoo.de
          <br />
          Eingetragen beim Amtsgericht Stuttgart HRB 752899
          <br />
          Geschäftsführer: Meidong Xu
          <br />
          USt-Id.-Nr.: DE300389735
          <br />
        </section>
      </div>
    )
  }
}
