import React from 'react'


export default class TestimonialArea extends React.Component {
    state = {

    }
    render() {
        const { title, subTitle, contactArr = [] } = this.props
        return (
            <section id="testimonial" class="testimonial-area">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="section-title text-center pb-20">
                                <h3 class="title">{title}</h3>
                                <p class="text">
                                    {
                                        subTitle
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row testimonial-active"> {
                                contactArr.map((item,idx) => {
                                    const { description, name, title } = item
                                    return (
                                        <div class="col-lg-4">
                                            <div class="single-testimonial mt-30 mb-30 text-center">
                                                <div class="testimonial-image">
                                                    <img src={`./static/assets/images/author-${idx+1}.jpg`}alt="Author" />
                                                </div>
                                                <div class="testimonial-content">
                                                    <p class="text">
                                                        {description}
                                                    </p>
                                                    <h6 class="author-name">{name}</h6>
                                                    <span class="sub-title">{title}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}