import React from 'react'
import $ from 'jquery'
import { WOW } from 'wowjs'
import CarouselItem from '../component/CarouselItem'

import languageIcon from '../assets/language.png'

class Head extends React.Component {
  state = {
    carousel: [
      {
        backgroundImage: 'url(assets/images/slider-1.jpg)',
        title: 'test',
      },
      {
        backgroundImage: 'url(assets/images/slider-1.jpg)',
        title: 'test2',
      },
      {
        backgroundImage: 'url(assets/images/slider-1.jpg)',
        title: 'test3',
      },
    ],
  }

  componentDidMount() {
    $(window).on('scroll', function (event) {
      var scroll = $(window).scrollTop()
      if (scroll < 10) {
        $('.navbar-area').removeClass('sticky')
      } else {
        $('.navbar-area').addClass('sticky')
      }
    })

    $('.navbar-nav a').on('click', function () {
      $('.navbar-collapse').removeClass('show')
    })

    $('.navbar-toggler').on('click', function () {
      $(this).toggleClass('active')
    })

    $('.navbar-nav a').on('click', function () {
      $('.navbar-toggler').removeClass('active')
    })

    var scrollLink = $('.page-scroll')
    $(window).scroll(function () {
      var scrollbarLocation = $(this).scrollTop()

      scrollLink.each(function () {
        if ($(this.hash) && $(this.hash).offset()) {
          var sectionOffset = $(this.hash).offset().top - 73

          if (sectionOffset <= scrollbarLocation) {
            $(this).parent().addClass('active')
            $(this).parent().siblings().removeClass('active')
          }
        }
      })
    })
    new WOW().init()
  }

  scrollIntoView(evt) {
   
    evt.preventDefault()
    const target = evt.target
    console.log(target.href)
    var widget = document.getElementById(target.href.split('#')[1])
    widget.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
  render() {
    const { onLanguageChange, carousel = [], lang } = this.props

    return (
      <section className="header-area">
        <div className="navbar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="#">
                    <img src="./static/assets/images/logo.png" alt="Logo" />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarEight"
                    aria-controls="navbarEight"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarEight"
                  >
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item active">
                        <a
                          className="page-scroll"
                          onClick={(evt) => {
                            evt.preventDefault()
                            var widget = document.getElementById('home')
                            widget.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                            })
                          }}
                          href="#home"
                        >
                          {lang === 'cn' ? '首页' : 'HOME'}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" onClick={(evt) => {
                            evt.preventDefault()
                            var widget = document.getElementById('about')
                            widget.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                            })
                          }} href="#about">
                          {lang === 'cn' ? '介绍' : 'ADVANTAGES'}
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a className="page-scroll" href="#portfolio">
                          PORTFOLIO
                        </a>
                      </li> */}
                      <li className="nav-item">
                        <a className="page-scroll" href="#pricing"  onClick={this.scrollIntoView}>
                          {lang === 'cn' ? '价格方案' : 'PRICING'}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="#testimonial" onClick={this.scrollIntoView}>
                          {lang === 'cn' ? '客户' : 'CLIENTS'}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="#call-action" onClick={this.scrollIntoView}>
                          {lang === 'cn' ? '物流追踪' : 'TRACKING'}
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="page-scroll" href="#contact" onClick={this.scrollIntoView}>
                          {lang === 'cn' ? '联系我们' : 'CONTACT'}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="navbar-btn d-none  d-lg-inline-block">
                    <span className="menu-bar" anchor="#side-menu-right">
                      <i className="lni-menu"></i>
                    </span>
                  </div>

                  {/*语言选择*/}
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    <button
                      className=""
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarLang"
                      aria-controls="navbarLang"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      style={{
                        border: 'none',
                        fontSize: '20px',
                        marginLeft: '20px',
                      }}
                    >
                      <i className="lni-world" />{' '}
                      {
                        [
                          { key: 'cn', name: 'language' },
                          { key: 'de', name: 'Deutsch' },
                          { key: 'en', name: 'English' },
                        ].find((item) => {
                          return item.key === lang
                        }).name
                      }
                    </button>
                    <div
                      className="collapse  sub-menu-bar"
                      id="navbarLang"
                      style={{
                        position: 'absolute',
                        marginTop: '10px',
                        left: '20px',
                      }}
                    >
                      <ul
                        className="ml-auto"
                        style={{ background: 'white', borderRadius: '5px' }}
                      >
                        {[
                          { key: 'cn', name: 'language' },
                          { key: 'de', name: 'Deutsch' },
                          { key: 'en', name: 'English' },
                        ].map((val) => {
                          const { key, name } = val
                          return (
                            <li
                              className="nav-item active"
                              onClick={() => {
                                onLanguageChange && onLanguageChange(key)
                              }}
                              style={{
                                fontSize: '15px',
                                padding: '10px',
                                cursor: 'pointer',
                              }}
                            >
                              {name}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div id="home" className="slider-area">
          <div className="bd-example">
            <div
              id="carouselOne"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                {carousel.map((data, index) => {
                  return (
                    <li
                      data-target="#carouselOne"
                      data-slide-to={index}
                      className={index === 0 ? 'active' : ''}
                    ></li>
                  )
                })}
              </ol>
              <div className="carousel-inner">
                {carousel.map((data, idx) => {
                  return <CarouselItem {...data} active={idx === 0} />
                })}
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselOne"
                role="button"
                data-slide="prev"
              >
                <i className="lni-arrow-left-circle"></i>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselOne"
                role="button"
                data-slide="next"
              >
                <i className="lni-arrow-right-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Head
