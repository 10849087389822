import React from 'react'
import qs from 'qs'
import './track.css'

export default class Track extends React.Component {
    state = {
        data: []
    }
    async componentDidMount() {
        const { location: { search } } = this.props
        const query = search.split('?')[1]
        const queryObj = qs.parse(query)
        const { data } = await this.fetchData(queryObj.id)
        this.setState({
            data
        })
    }
    async fetchData(trackingNum) {
        const API = `https://voltoo.net/transfer/?nu=${trackingNum}`
        const respData = await fetch(API).then((resp) => {
            if (!resp.ok) {
                return {data: null}
            }
            return resp.json()
        }).catch((err) => {
            alert(err.message)
        })
        return respData
    }
    render() {
        const {data} =this.state
        return (
            <div data-mohe-type="kuaidi_new" className="g-mohe " id="mohe-kuaidi_new">
                <div id="mohe-kuaidi_new_nucom">
                    <div className="mohe-wrap mh-wrap">
                        <div className="mh-cont mh-list-wrap mh-unfold">
                            <div className="mh-list">
                                <ul>
                                    {
                                      data ?  data.reverse().map((item, idx) => {
                                            const { time,context,location } = item
                                            return (
                                                <li className={idx === 0 ? "first" : ""}>
                                                    <p>{time}</p>
                                                    <p>{location + context}</p>
                                                    <span className="before"></span>
                                                    <span className="after"></span>
                                                    {
                                                       idx === 0 ?   <i className="mh-icon mh-icon-new"></i> : null
                                                    }
                                                  
                                                </li>
                                            )
                                        }) : <h1>查无结果</h1>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}