import React from 'react'
import Head from '../section/Head'
import SideBar from '../layout/SideBar'
import Overlay from '../layout/Overlay'
import About from '../section/About'
import Portfolio from '../section/Portfolio'
import Pricing from '../section/Pricing'
import CallAction from '../section/CallAction'
import TestimonialArea from '../section/TestimonialArea'
import Client from '../section/Client'
import Contact from '../section/Contact'
import Foot from '../section/Foot'
import BackToTop from '../layout/BackToTop'
import Recordnumber from '../layout/Recordnumber'
import CookieUse from '../layout/CookieUse'

export default class Index extends React.Component {
  state = {
    HeadSec: {},
    AboutSec: {},
    CallActionSec: {},
    TestimonialSec: {},
    ContactSec: {},
    lang: 'en',
  }
  componentDidMount() {
    var lang = navigator.language || navigator.userLanguage //常规浏览器语言和IE浏览器
    lang = lang.substr(0, 2)
    switch (lang) {
      case 'zh':
        lang = 'cn'
        break
      case 'de':
        lang = 'de'
        break
      default:
        lang = 'en'
    }
    this.setState({
      lang: 'en',
    })
    this.fetchData(lang)
  }

  async fetchData(lang) {
    const API = `https://voltoo.net/index/content/?language=${lang}`
    const resp = await fetch(API).then(res => {
      if (!res.ok) {
        throw new Error('test')
      }
      return res.json()
    })
    const data = {
      HeadSec: {},
      AboutSec: {},
      PricingSec: {},
      CallActionSec: {},
      TestimonialSec: {},
      ContactSec: {},
    }
    // data处理函数
    Object.keys(resp).forEach(key => {
      const [dataNum, ...spaceNumArr] = key
      const spaceNum = spaceNumArr.join('')
      const val = resp[key]
      switch (spaceNum) {
        case '11':
          !data.HeadSec.carousel && (data.HeadSec.carousel = [])
          data.HeadSec.carousel.push({
            title: resp[key],
            backgroundImage: `url(./static/assets/images/slider-${
              data.HeadSec.carousel.length + 1
            }.jpg)`,
          })
          break
        case '21':
          data.AboutSec.title = resp[key]
          break
        case '22':
          data.AboutSec.subTitle = resp[key]
          break
        case '31':
          if (data.AboutSec.contentBoxArr) {
            if (data.AboutSec.contentBoxArr[0]) {
            } else {
              data.AboutSec.contentBoxArr[0] = {}
            }
          } else {
            data.AboutSec.contentBoxArr = [{}]
          }
          data.AboutSec.contentBoxArr[0].summary = val
          break
        case '32':
          if (data.AboutSec.contentBoxArr) {
            if (data.AboutSec.contentBoxArr[0]) {
            } else {
              data.AboutSec.contentBoxArr[0] = {}
            }
          } else {
            data.AboutSec.contentBoxArr = [{}]
          }
          data.AboutSec.contentBoxArr[0].description = val
          break
        case '33':
          if (data.AboutSec.contentBoxArr) {
            if (data.AboutSec.contentBoxArr[1]) {
            } else {
              data.AboutSec.contentBoxArr[1] = {}
            }
          } else {
            data.AboutSec.contentBoxArr = [{}, {}]
          }
          data.AboutSec.contentBoxArr[1].summary = val
          break
        case '34':
          if (data.AboutSec.contentBoxArr) {
            if (data.AboutSec.contentBoxArr[1]) {
            } else {
              data.AboutSec.contentBoxArr[1] = {}
            }
          } else {
            data.AboutSec.contentBoxArr = [{}, {}]
          }
          data.AboutSec.contentBoxArr[1].description = val
          break
        case '35':
          if (data.AboutSec.contentBoxArr) {
            if (data.AboutSec.contentBoxArr[2]) {
            } else {
              data.AboutSec.contentBoxArr[2] = {}
            }
          } else {
            data.AboutSec.contentBoxArr = [{}, {}, {}]
          }
          data.AboutSec.contentBoxArr[2].summary = val
          break
        case '36':
          if (data.AboutSec.contentBoxArr) {
            if (data.AboutSec.contentBoxArr[2]) {
            } else {
              data.AboutSec.contentBoxArr[2] = {}
            }
          } else {
            data.AboutSec.contentBoxArr = [{}, {}, {}]
          }
          data.AboutSec.contentBoxArr[2].description = val
          break
        case '37':
          if (data.AboutSec.contentBoxArr) {
            if (data.AboutSec.contentBoxArr[3]) {
            } else {
              data.AboutSec.contentBoxArr[3] = {}
            }
          } else {
            data.AboutSec.contentBoxArr = [{}, {}, {}, {}]
          }
          data.AboutSec.contentBoxArr[3].summary = val
          break
        case '38':
          if (data.AboutSec.contentBoxArr) {
            if (data.AboutSec.contentBoxArr[3]) {
            } else {
              data.AboutSec.contentBoxArr[3] = {}
            }
          } else {
            data.AboutSec.contentBoxArr = [{}, {}, {}, {}]
          }
          data.AboutSec.contentBoxArr[3].description = val
          break
        case '41':
          data.PricingSec.title = val
          break
        case '42':
          data.PricingSec.subTitle = val
          break
        case '51':
          if (data.PricingSec.pricePackageArr) {
            if (data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65]) {
            } else {
              data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
            }
          } else {
            data.PricingSec.pricePackageArr = []
            data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
          }
          data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65].name = val
          break
        case '52':
          if (data.PricingSec.pricePackageArr) {
            if (data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65]) {
            } else {
              data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
            }
          } else {
            data.PricingSec.pricePackageArr = []
            data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
          }
          data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65].price = val
          break
        case '53':
          if (data.PricingSec.pricePackageArr) {
            if (data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65]) {
            } else {
              data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
            }
          } else {
            data.PricingSec.pricePackageArr = []
            data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
          }
          data.PricingSec.pricePackageArr[
            dataNum.charCodeAt() - 65
          ].dateFormat = val
          break
        case '54':
          if (data.PricingSec.pricePackageArr) {
            if (data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65]) {
            } else {
              data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
            }
          } else {
            data.PricingSec.pricePackageArr = []
            data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
          }

          data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65].content
            ? data.PricingSec.pricePackageArr[
                dataNum.charCodeAt() - 65
              ].content.push({
                content: val,
              })
            : (data.PricingSec.pricePackageArr[
                dataNum.charCodeAt() - 65
              ].content = [
                {
                  content: val,
                },
              ])
          break
        case '55':
          if (data.PricingSec.pricePackageArr) {
            if (data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65]) {
            } else {
              data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
            }
          } else {
            data.PricingSec.pricePackageArr = []
            data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
          }

          data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65].content
            ? data.PricingSec.pricePackageArr[
                dataNum.charCodeAt() - 65
              ].content.push({
                content: val,
              })
            : (data.PricingSec.pricePackageArr[
                dataNum.charCodeAt() - 65
              ].content = [
                {
                  content: val,
                },
              ])
          break
        case '56':
          if (data.PricingSec.pricePackageArr) {
            if (data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65]) {
            } else {
              data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
            }
          } else {
            data.PricingSec.pricePackageArr = []
            data.PricingSec.pricePackageArr[dataNum.charCodeAt() - 65] = {}
          }
          data.PricingSec.pricePackageArr[
            dataNum.charCodeAt() - 65
          ].purchaseBtn = {
            name: val,
          }
          break
        case '57':
          data.CallActionSec.title = val
          break
        case '58':
          data.CallActionSec.description = val
          break
        case '59':
          data.CallActionSec.packageID = val
          break
        case '61':
          data.TestimonialSec.title = val
          break
        case '62':
          data.TestimonialSec.subTitle = val
          break
        case '71':
          if (data.TestimonialSec.contactArr) {
            if (data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65]) {
            } else {
              data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65] = {}
            }
          } else {
            data.TestimonialSec.contactArr = []
            data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65] = {}
          }
          data.TestimonialSec.contactArr[
            dataNum.charCodeAt() - 65
          ].description = val
          break
        case '72':
          if (data.TestimonialSec.contactArr) {
            if (data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65]) {
            } else {
              data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65] = {}
            }
          } else {
            data.TestimonialSec.contactArr = []
            data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65] = {}
          }
          data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65].name = val
          break
        case '73':
          if (data.TestimonialSec.contactArr) {
            if (data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65]) {
            } else {
              data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65] = {}
            }
          } else {
            data.TestimonialSec.contactArr = []
            data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65] = {}
          }
          data.TestimonialSec.contactArr[dataNum.charCodeAt() - 65].title = val
          break
        case '81':
          data.ContactSec.title = val
          break
        case '82':
          data.ContactSec.subTitle = val
          break
        case '83':
          data.ContactSec.summary = val
          break
        case '84':
          data.ContactSec.description = val
          break
        case '85':
          data.ContactSec.place = val
          break
        case '86':
          data.ContactSec.phone = val
          break
        case '87':
          data.ContactSec.email = val
          break
        case '88':
          data.ContactSec.phone2 = val
          break
        default:
          console.error(`不支持的数据 ${spaceNum} ${val}`)
      }
    })

    this.setState({ ...data, lang })
  }

  onLanguageChangeHandle(lang) {
    this.fetchData(lang)
    this.setState({
      lang: lang,
    })
  }

  render() {
    const {
      HeadSec,
      AboutSec,
      PricingSec,
      CallActionSec,
      TestimonialSec,
      ContactSec,
      lang,
    } = this.state
    return (
      <div
        className='Index'
        style={{
          position: 'relative',
        }}
      >
        <Head
          {...HeadSec}
          onLanguageChange={this.onLanguageChangeHandle.bind(this)}
          lang={lang}
        />
        <SideBar lang={lang} />
        <Overlay />
        <About {...AboutSec} />
        {/* <Portfolio /> */}
        <Pricing {...PricingSec} lang={lang} />

        <TestimonialArea {...TestimonialSec} />
        <CallAction {...CallActionSec} />
        {/* <Client /> */}
        <Contact {...ContactSec} />
        {/* <Foot /> */}

        <BackToTop />
        <Recordnumber lang={lang} />
        <CookieUse />
      </div>
    )
  }
}
