import React from 'react'



class About extends React.Component {

    state = {}
    render() {
        const { aboutImage, title, subTitle, contentBoxArr } = this.props
        return (
            <section id="about" class="about-area">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8">
                            <div class="about-image text-center wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="100">
                                <img src={aboutImage} alt="services" />
                            </div>
                            <div class="section-title text-center mt-30 pb-40">
                                <h4 class="title wow fadeInUp" data-wow-duration="0s" data-wow-delay="0s">
                                    {title}
                                </h4>
                                <p class="text wow fadeInUp" data-wow-duration="0s" data-wow-delay="0s">
                                    {
                                        subTitle
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        {
                            contentBoxArr.map((data,idx) => {
                                const { icon, summary, description } = data
                                
                                return (
                                    <div class="col-lg-6">
                                        <div class="single-about d-sm-flex mt-30 wow fadeInUp" data-wow-duration="0s" data-wow-delay={`0s`}>
                                            <div class="about-icon">
                                                <img src={icon || './static/assets/images/icon-1.png'} alt="Icon" />
                                            </div>
                                            <div class="about-content media-body">
                                                <h4 class="about-title">{summary}</h4>
                                                <p class="text">
                                                    {
                                                        description
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            </section>
        )
    }
}


About.defaultProps ={
    title: "a21",
    subTitle: "a22",
    aboutImage: "./static/assets/images/services.png",
    contentBoxArr: [
        {
            icon: "./static/assets/images/icon-1.png",
            summary: "Powerful templates",
            description: "Poorly designed presentations are a thing of the past. Create beautiful and high-quality content that is aligned."
        },
        {
            icon: "",
            summary: "",
            description: ""
        },
        {
            icon: "",
            summary: "",
            description: ""
        }
    ]
}

export default About