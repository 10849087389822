import React from 'react'

export default class CookieUse extends React.Component {
  state = {
    hidden: false,
  }
  render() {
    const isSelected = false // typeof window.localStorage.getItem('acceptCookie') !== 'undefined'

    return isSelected || this.state.hidden ? null : (
      <div
        style={{
          position: 'sticky',
          bottom: 0,
          width: '100%',
          display: 'flex',
          backgroundColor: 'white',
          fontSize: 16,
          padding: '10px 40px',
          zIndex: 9999,
        }}
      >
        <div>
          This site uses cookies to provide an improved digital experience. You
          can learn more about the cookies we use as well as how you can change
          your cookie settings by clicking here. By continuing to use this site
          without changing your settings, you are agreeing to our use of
          cookies. Review voltoo’s Privacy Statement to learn more
        </div>
        <button
          onClick={() => {
            this.setState({
              hidden: true,
            })
            window.localStorage.setItem('acceptCookie', true)
          }}
          style={{
            lineHeight: '52px',
            border: 0,
          }}
        >
          Accept
        </button>
      </div>
    )
  }
}
