import React from 'react'
import logo from './logo.svg'
import './App.css'
import $ from 'jquery'
import WOW from 'wowjs'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from 'react-router-dom'

import Index from './page/index'
import Track from './page/track'
import Impressum from './page/impressum'
import Datenschutz from './page/datenschutz'

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/track" component={Track} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/datenschutz" component={Datenschutz} />
          {/* <Redirect to="/"/> */}
        </Switch>
      </Router>
    )
  }
}

export default App
