import React from 'react'

class Portfolio extends React.Component {
    render() {
        return (
            <section id="portfolio" class="portfolio-area">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="section-title text-center pb-20">
                                <h3 class="title">Our Portfolio</h3>
                                <p class="text">
                                    Stop wasting time and money designing and managing a website that doesn’t get results. Happiness guaranteed!
            </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="portfolio-menu pt-30 text-center">
                                <ul>
                                    <li data-filter="*" class="active">ALL WORK</li>
                                    <li data-filter=".branding-3">BRANDING</li>
                                    <li data-filter=".marketing-3">MARKETING</li>
                                    <li data-filter=".planning-3">PLANNING</li>
                                    <li data-filter=".research-3">RESEARCH</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row grid">
                        <div class="col-lg-4 col-sm-6 branding-3 planning-3">
                            <div class="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <div class="portfolio-image">
                                    <img src="./static/assets/images/portfolio-1.png" alt="" />
                                    <div class="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div class="portfolio-content">
                                            <div class="portfolio-icon">
                                                <a class="image-popup" href="./static/assets/images/portfolio-1.png">
                                                    <i class="lni-zoom-in"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                            <div class="portfolio-icon">
                                                <a href="#">
                                                    <i class="lni-link"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portfolio-text">
                                    <h4 class="portfolio-title">
                                        <a href="#">Graphics Design</a>
                                    </h4>
                                    <p class="text">
                                        Short description for the ones who look for something new. Awesome!
              </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 marketing-3 research-3">
                            <div class="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
                                <div class="portfolio-image">
                                    <img src="./static/assets/images/portfolio-2.png" alt="" />
                                    <div class="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div class="portfolio-content">
                                            <div class="portfolio-icon">
                                                <a class="image-popup" href="./static/assets/images/portfolio-2.png">
                                                    <i class="lni-zoom-in"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                            <div class="portfolio-icon">
                                                <a href="#">
                                                    <i class="lni-link"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portfolio-text">
                                    <h4 class="portfolio-title">
                                        <a href="#">Graphics Design</a>
                                    </h4>
                                    <p class="text">
                                        Short description for the ones who look for something new. Awesome!
              </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 branding-3 marketing-3">
                            <div class="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.7s">
                                <div class="portfolio-image">
                                    <img src="./static/assets/images/portfolio-3.png" alt="" />
                                    <div class="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div class="portfolio-content">
                                            <div class="portfolio-icon">
                                                <a class="image-popup" href="./static/assets/images/portfolio-3.png">
                                                    <i class="lni-zoom-in"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                            <div class="portfolio-icon">
                                                <a href="#">
                                                    <i class="lni-link"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portfolio-text">
                                    <h4 class="portfolio-title">
                                        <a href="#">Graphics Design</a>
                                    </h4>
                                    <p class="text">
                                        Short description for the ones who look for something new. Awesome!
              </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 planning-3 research-3">
                            <div class="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <div class="portfolio-image">
                                    <img src="./static/assets/images/portfolio-4.png" alt="" />
                                    <div class="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div class="portfolio-content">
                                            <div class="portfolio-icon">
                                                <a class="image-popup" href="./static/assets/images/portfolio-4.png">
                                                    <i class="lni-zoom-in"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                            <div class="portfolio-icon">
                                                <a href="#">
                                                    <i class="lni-link"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portfolio-text">
                                    <h4 class="portfolio-title">
                                        <a href="#">Graphics Design</a>
                                    </h4>
                                    <p class="text">
                                        Short description for the ones who look for something new. Awesome!
              </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 marketing-3">
                            <div class="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
                                <div class="portfolio-image">
                                    <img src="./static/assets/images/portfolio-5.png" alt="" />
                                    <div class="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div class="portfolio-content">
                                            <div class="portfolio-icon">
                                                <a class="image-popup" href="./static/assets/images/portfolio-5.png">
                                                    <i class="lni-zoom-in"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                            <div class="portfolio-icon">
                                                <a href="#">
                                                    <i class="lni-link"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portfolio-text">
                                    <h4 class="portfolio-title">
                                        <a href="#">Graphics Design</a>
                                    </h4>
                                    <p class="text">
                                        Short description for the ones who look for something new. Awesome!
              </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 planning-3">
                            <div class="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.7s">
                                <div class="portfolio-image">
                                    <img src="./static/assets/images/portfolio-6.png" alt="" />
                                    <div class="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div class="portfolio-content">
                                            <div class="portfolio-icon">
                                                <a class="image-popup" href="./static/assets/images/portfolio-6.png">
                                                    <i class="lni-zoom-in"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                            <div class="portfolio-icon">
                                                <a href="#">
                                                    <i class="lni-link"></i>
                                                </a>
                                                <img src="./static/assets/images/shape.svg" alt="shape" class="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portfolio-text">
                                    <h4 class="portfolio-title">
                                        <a href="#">Graphics Design</a>
                                    </h4>
                                    <p class="text">
                                        Short description for the ones who look for something new. Awesome!
              </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


export default Portfolio