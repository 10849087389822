import React from 'react'
export default class Datenschutz extends React.Component {
  render() {
    return (
      <div>
        <div className="navbar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="/">
                    <img src="./static/assets/images/logo.png" alt="Logo" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section
          style={{
            backgroundColor: '#f5f6f7',
            display: 'flex',
            justifyContent: 'center',
            padding: '100px',
            fontSize: '25px',
            lineHeight: '34px',
          }}
          dangerouslySetInnerHTML={{
            __html: `  <div>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        Einführung
                      </span>
                    </p>
                    <p style="font-size:12pt; line-height:108%; margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
                        Der Datenschutz ist von besonderer Bedeutung für die
                        Geschäftsführung von
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Voltoo GmbH,
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Karlsruherstr.9
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        , 70771 Leinfelden-Echterdingen,
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Deutschland
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        , UID.Nr.
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        DE300389735
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        
                        (im Folgenden:
                      </span>
                      <span style="color:#727272; font-family:'等线 Light'; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        ).
                      </span>
                      <span style="font-family:Arial; font-size:12pt">Voltoo</span>
                      <span style="color:#ff0000; font-family:Arial; font-size:12pt">
                        
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        ist der Datencontroller Ihrer persönlichen Daten. Wir haben
                        einen Datenschutzbeauftragten und Sie können uns per E-Mail oder
                        unter folgender Adresse kontaktieren:
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Karlsruherstr.9
                      </span>
                    </p>
                    <p style="font-size:10pt; line-height:108%; margin:0pt">
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        70771 Leinfelden-Echterdingen,
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Deutschland
                      </span>
                    </p>
                    <p style="font-size:12pt; line-height:108%; margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Die Bearbeitung personenbezogener Daten wie Name, Adresse,
                        E-Mail-Adresse oder Telefonnummer eines Kunden erfolgt stets im
                        Einklang mit der Allgemeinen Datenschutzverordnung und den für
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        geltenden länderspezifischen Datenschutzbestimmungen. Durch
                        diese Datenschutzrichtlinie möchte unser Unternehmen die
                        Öffentlichkeit über Art, Umfang und Zweck der von uns
                        gesammelten, verwendeten und verarbeiteten personenbezogenen
                        Daten informieren. Darüber hinaus wird der Kunde über seine
                        Rechte gemäß dieser Datenschutzrichtlinie informiert.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        als für die Verarbeitung Verantwortlicher hat zahlreiche
                        technische und organisatorische Maßnahmen ergriffen, um den
                        bestmöglichen Schutz der über diese Website verarbeiteten
                        personenbezogenen Daten zu gewährleisten.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Durch die Zusammenarbeit mit
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        erklären Sie sich mit der Erfassung und Verwendung Ihrer Daten
                        gemäß dieser Richtlinie einverstanden.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        ZUSAMMENFASSUNG
                      </span>
                    </p>
                    <ul type="disc" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:serif; font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt">
                          Wir geben, verkaufen, vermieten oder leihen Ihre Daten niemals
                          an Dritte weiter.
                        </span>
                      </li>
                      <li style=" color:#727272; font-family:serif; font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt">
                          Wir werden Sie niemals spammen.
                        </span>
                      </li>
                      <li style=" color:#727272; font-family:serif; font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt">
                          Wir werden Ihre Daten niemals ohne Ihre Zustimmung verwenden.
                        </span>
                      </li>
                      <li style=" color:#727272; font-family:serif; font-size:10pt; margin:5pt 0pt 0pt 27.6pt; padding-left:8.4pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt">
                          Im Rahmen der Einhaltung der DSGVO haben wir unsere
                          Datenschutzrichtlinie vereinfacht.
                        </span>
                      </li>
                    </ul>
                    <p style="font-size:12pt; line-height:108%; margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Ihre personenbezogenen Daten werden aufgrund der gesetzlichen
                        Bestimmungen bearbeitet oder sind zur Erfüllung eines Vertrages
                        oder zum Ergreifen von Maßnahmen vor Vertragsabschluss oder zum
                        Zwecke unserer berechtigten Interessen erforderlich, es sei
                        denn, diese Interessen werden von der Bundesregierung außer
                        Kraft gesetzt durch Interessen oder Grundrechte und -freiheiten
                        der betroffenen Personen, die den Schutz personenbezogener Daten
                        erfordern. Können personenbezogene Daten auf der Grundlage der
                        vorgenannten Rechtsgrundlage nicht verarbeitet werden, bitten
                        wir um Ihre Einwilligung. Wenn die Bearbeitung auf Ihrer
                        Einwilligung beruht, haben Sie das Recht, die Einwilligung
                        jederzeit zu widerrufen. Sie können uns jederzeit über den
                        Widerruf der Einwilligung per E-Mail-Adresse informieren: E-Mail
                        oder per Adresse:
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Karlsruherstr.9
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        , 70771 Leinfelden-Echterdingen,
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Deutschland
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Ein solcher Widerruf hat keine Auswirkung auf die Rechtmäßigkeit
                        der Bearbeitung aufgrund einer Einwilligung vor seinem Widerruf.
                      </span>
                    </p>
                    <p style="font-size:12pt; line-height:108%; margin:0pt 0pt 0pt 18pt; text-indent:-18pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        1.
                      </span>
                      <span style="font:7.0pt 'Times New Roman'">
                        &#xa0;&#xa0;&#xa0;&#xa0;
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Außerdem haben Sie im Rahmen der Einhaltung der DSGVO folgende
                        Rechte:
                      </span>
                      <br />
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        a) Auskunftsrecht
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        : Sie haben das Recht, von uns eine Bestätigung darüber zu
                        erhalten, ob personenbezogene Daten, die Sie betreffen,
                        verarbeitet werden, und, sofern dies der Fall ist, Zugang zu den
                        personenbezogenen Daten zu erhalten. Zu den Informationen über
                        den Zugang gehören, ohne darauf beschränkt zu sein, die Zwecke
                        der Verarbeitung, die Kategorien der betroffenen
                        personenbezogenen Daten, die Empfänger oder Kategorien von
                        Empfängern, denen die personenbezogenen Daten mitgeteilt wurden
                        oder werden. Das Auskunftsrecht ist jedoch kein unbedingtes
                        Recht, und Rechte und Interessen anderer Personen können Ihr
                        Auskunftsrecht einschränken.
                      </span>
                      <br />
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        b)
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Sie haben das Recht, eine Kopie der in Bearbeitung befindlichen
                        personenbezogenen Daten zu erhalten. Für alle weiteren von Ihnen
                        angeforderten Kopien können wir eine angemessene Gebühr erheben,
                        die sich nach den Verwaltungskosten richtet.
                      </span>
                      <br />
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        c) Recht auf Berichtigung:
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Sie haben das Recht, bei uns unverzüglich die Berichtigung
                        unrichtiger personenbezogener Daten zu erwirken. Unter
                        Berücksichtigung der Zwecke der Verarbeitung haben Sie das
                        Recht, unvollständige personenbezogene Daten auch durch Angabe
                        einer ergänzenden Erklärung vervollständigen zu lassen.
                      </span>
                      <br />
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        d) Recht auf Löschung
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        („Recht auf Vergessenwerden“): Unter bestimmten Umständen haben
                        Sie das Recht, von uns die Löschung Ihrer personenbezogenen
                        Daten zu verlangen, und wir sind verpflichtet, diese
                        personenbezogenen Daten zu löschen.
                      </span>
                      <br />
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        e) Recht auf Einschränkung der Bearbeitung:
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Unter bestimmten Voraussetzungen haben Sie das Recht, von uns
                        eine Einschränkung der Bearbeitung Ihrer personenbezogenen Daten
                        zu erhalten. In diesem Fall werden diese Daten gekennzeichnet
                        und können nur für bestimmte Zwecke bearbeitet werden.
                      </span>
                      <br />
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        f) Widerspruchsrecht:
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        &#xa0;
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Unter bestimmten Voraussetzungen haben Sie jederzeit das Recht,
                        der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen,
                        sei es aus Gründen seiner besonderen Situation oder wenn
                        personenbezogene Daten für Direktmarketingzwecke verarbeitet
                        werden, haben Sie das Recht, dies zu verlangen von uns, um die
                        Verarbeitung Ihrer personenbezogenen Daten zu stoppen.
                      </span>
                      <br />
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        g) Recht auf Datenübertragbarkeit
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        : Unter bestimmten Voraussetzungen haben Sie das Recht, Ihre uns
                        zur Verfügung gestellten personenbezogenen Daten in einem
                        strukturierten, allgemein verwendeten und maschinenlesbaren
                        Format zu erhalten und diese Daten an einen anderen
                        Verantwortlichen zu übermitteln ohne unser Hindernis.
                      </span>
                      <br />
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        h)
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Wenn Sie mehr erfahren oder eines der oben genannten Rechte
                        nutzen möchten, wenden Sie sich bitte an Voltoounter der
                        E-Mail-Adresse: E-Mail oder unter der
                      </span>
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Karlsruherstr.9 70771 Leinfelden-Echterdingen, Deutschland
                      </span>
                    </p>
                    <p style=" margin:0pt">
                      <br />
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        i)
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Wenn Sie der Meinung sind, dass Ihre Rechte verletzt wurden,
                        haben Sie das Recht, eine Beschwerde bei der nationalen
                        Aufsichtsbehörde einzureichen.
                      </span>
                    </p>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:Arial; font-size:12pt; margin:5pt 0pt 0pt 33.01pt; padding-left:2.99pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Wie wir Ihre Informationen behandeln
                        </span>
                      </li>
                    </ol>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt; text-decoration:underline">
                        2.1. Wir werden Ihre Daten niemals an Dritte weitergeben,
                        verkaufen, vermieten oder ausleihen.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        – Wir respektieren Ihre Privatsphäre und Ihr Vertrauen. Wir
                        werden Ihre Daten daher niemals ohne Ihre ausdrückliche
                        Genehmigung an Dritte weitergeben, verkaufen, vermieten oder
                        ausleihen.
                      </span>
                    </p>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt; text-decoration:underline">
                        2.2 Wir werden Sie niemals spammen.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        – Wir nutzen keinerlei Newsletter-Dienste.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:Arial; font-size:12pt; margin:5pt 0pt 0pt 33.01pt; padding-left:2.99pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Zwecke der Verarbeitung personenbezogener Daten
                        </span>
                      </li>
                    </ol>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        3.1. Ihre personenbezogenen Daten werden aufgrund der
                        gesetzlichen Bestimmungen verarbeitet, oder die Verarbeitung ist
                        erforderlich, um einen Vertrag abzuschließen, um vor dem
                        Abschluss eines Vertrags Schritte einzuleiten oder um unsere
                        berechtigten Interessen zu vertreten, es sei denn, diese
                        Interessen werden außer Kraft gesetzt durch die Interessen oder
                        Grundrechte und -freiheiten der betroffenen Personen, die den
                        Schutz personenbezogener Daten benötigen. Können
                        personenbezogene Daten aufgrund der vorgenannten Rechtsgrundlage
                        nicht verarbeitet werden, bitten wir um Ihre Einwilligung. Bei
                        der Kommunikation per E-Mail aus rechtlichen Gründen können Sie
                        uns jederzeit benachrichtigen, dass Sie unsere E-Mail nicht
                        erhalten möchten. Sie können dies tun, indem Sie eine E-Mail an
                        eine E-Mail-Adresse senden, von der Sie die Nachricht erhalten
                        haben.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        3.2. Ihre personenbezogenen Daten werden nur bearbeitet, wenn
                        die Erfüllung von Rechten und Pflichten aus
                        Geschäftsbeziehungen, zu Marketingzwecken oder zur Verbesserung
                        unseres Geschäfts und Ihrer Nutzererfahrung erforderlich ist.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        3.3. Personenbezogene Daten werden zumeist direkt von Ihnen beim
                        Ausfüllen eines bestimmten Formulars oder über öffentlich
                        zugängliche Informationen erhoben.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <ol start="4" type="1" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:Arial; font-size:12pt; margin:5pt 0pt 0pt 33.01pt; padding-left:2.99pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Welche Informationen sammeln wir?
                        </span>
                      </li>
                    </ol>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt; text-decoration:underline">
                        4.1. Allgemeine Informationen – Kontaktformular
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        – Wir erfassen Informationen von Ihnen, wenn Sie das
                        Kontaktformular auf unserer Website ausfüllen – Name, E-Mail,
                        Telefonnummer, Land und Informationen zu Ihren Anforderungen.
                        Wenn Sie uns über das Kontaktformular auf unserer Kontaktseite
                        oder über einen E-Mail-Link kontaktieren, können wir diese Daten
                        in unserem Pipedrive-Konto speichern, um Ihr Engagement als
                        (potenzieller) Kunde oder Partner zu verfolgen.
                      </span>
                    </p>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt; text-decoration:underline">
                        4.2. Sammeln von Informationen über die Live-Chat-App
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        – Sie können die Live-Chat-App auf unserer Website von Tawk.to
                        (www.tawk.to) verwenden.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        – Wie die meisten Betreiber von Internetdiensten sammelt tawk.to
                        nicht personenbezogene Daten, die von Webbrowsern, Apps und
                        Servern in der Regel zur Verfügung gestellt werden, z. B.
                        Browsertyp, Spracheinstellung, geografischer Standort,
                        verweisende Website und Datum und Uhrzeit jeder Besucheranfrage.
                        Der Zweck von tawk.to bei der Erfassung nicht personenbezogener
                        Daten besteht darin, die Nutzung der Dienste durch die Besucher
                        von tawk.to besser zu verstehen und den Nutzern die Möglichkeit
                        zu geben, die Nutzung ihrer Dienste durch die Besucher zu
                        verstehen.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <ol start="5" type="1" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:Arial; font-size:12pt; margin:5pt 0pt 0pt 33.01pt; padding-left:2.99pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Grundlage für die Bearbeitung
                        </span>
                      </li>
                    </ol>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Der für die Bearbeitung Verantwortliche kann personenbezogene
                        Daten nur in folgendem Umfang bearbeiten: Hat die betroffene
                        Person der Bearbeitung ihrer personenbezogenen Daten zugestimmt,
                        ist die Bearbeitung für die Erfüllung eines Vertrags
                        erforderlich, an dem die betroffene Person beteiligt ist oder um
                        auf Verlangen der betroffenen Person vor Abschluss eines
                        Vertrages Schritte einzuleiten, ist eine Bearbeitung
                        erforderlich, um eine gesetzliche Verpflichtung zu erfüllen, der
                        der für die Bearbeitung Verantwortliche unterliegt. Die
                        Bearbeitung ist erforderlich, um die vitalen Interessen der
                        betroffenen Person zu schützen oder einer anderen natürlichen
                        Person ist die Bearbeitung zur Wahrnehmung einer Aufgabe im
                        öffentlichen Interesse oder zur Ausübung der dem für die
                        Bearbeitung Verantwortlichen übertragenen behördlichen
                        Befugnisse erforderlich; die Bearbeitung ist zum Zwecke der vom
                        für die Bearbeitung Verantwortlichen oder von einem Beauftragten
                        verfolgten berechtigten Interessen erforderlich Dritten, es sei
                        denn, diese Interessen werden durch die Interessen oder
                        Grundrechte und Grundfreiheiten der betroffenen Person außer
                        Kraft gesetzt, für die ein Schutz personenbezogene Daten
                        erforderlich ist, insbesondere wenn es sich bei der betroffenen
                        Person um ein Kind handelt.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <ol start="6" type="1" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:Arial; font-size:12pt; margin:5pt 0pt 0pt 33.01pt; padding-left:2.99pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Speicherung personenbezogener Daten
                        </span>
                      </li>
                    </ol>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Ihre gesammelten persönlichen Daten werden in einer sicheren
                        Umgebung gespeichert. Ihre persönlichen Daten sind vor
                        unbefugtem Zugriff, Offenlegung, Verwendung, Änderung oder
                        Zerstörung durch Organisationen oder Einzelpersonen geschützt.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Die für die oben genannten Zwecke gesammelten Informationen
                        werden nur so lange gespeichert, wie dies für die oben genannten
                        Zwecke erforderlich ist. Ihre persönlichen Daten werden nicht in
                        einer Form gespeichert, die Ihre Identifizierung länger
                        ermöglicht, als
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        es vernünftigerweise für erforderlich hält, den Zweck zu
                        erfüllen, für den sie gesammelt und verarbeitet wurden.
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        speichert bestimmte personenbezogene Daten für einen Zeitraum,
                        der durch Gesetze oder Vorschriften festgelegt ist, die
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        zur Speicherung von Daten verpflichten.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Wenn Sie uns Ihre Einwilligung erteilt haben, werden Ihre
                        personenbezogenen Daten bis zum Widerruf der Einwilligung
                        verarbeitet. Wenn Sie einen begründeten Widerspruch gegen die
                        Verarbeitung personenbezogener Daten aus rechtlichen
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Gründen einlegen, werden Ihre personenbezogenen Daten in Zukunft
                        nicht mehr verarbeitet.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Personenbezogene Daten können bis zum Ende eines Gerichts-,
                        Verwaltungs- oder außergerichtlichen Verfahrens einschließlich
                        der Frist für die Einreichung von Rechtsbehelfen verarbeitet
                        werden.
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        bewahrt bestimmte personenbezogene Daten für einen Zeitraum auf,
                        der durch Gesetze oder Vorschriften festgelegt ist, die den für
                        die Verarbeitung Verantwortlichen zur Speicherung von Daten
                        verpflichten.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <ol start="7" type="1" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:Arial; font-size:12pt; margin:5pt 0pt 0pt 33.01pt; padding-left:2.99pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Empfänger personenbezogener Daten
                        </span>
                      </li>
                    </ol>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Mit den oben genannten Informationen können Ihre persönlichen
                        Daten an unsere vertrauenswürdigen Partner weitergegeben werden,
                        die unser IT-System warten oder Dienstleistungen im Namen von
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        erbringen. Zum Beispiel für Marketing, Finanzen, Werbung,
                        Zahlungsabwicklung, Lieferung und andere Dienstleistungen. Die
                        vorgenannten Dienstleister sind gemäß den einschlägigen
                        Verträgen verpflichtet, die ihnen anvertrauten Daten nur im
                        Rahmen unserer gesetzlichen Bestimmungen und ausschließlich zu
                        den von uns ausdrücklich angegebenen Zwecken zu verwenden. Sie
                        sind außerdem verpflichtet, Ihre Daten angemessen zu schützen
                        und als persönliches Geheimnis zu behandeln.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <ol start="8" type="1" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:Arial; font-size:12pt; margin:5pt 0pt 0pt 33.01pt; padding-left:2.99pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Verwenden wir Cookies?
                        </span>
                      </li>
                    </ol>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Voltoo
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        verwendet Technologien, die als „Cookies“ bezeichnet werden, um
                        Ihre Website zu pflegen und ihre Funktionalität auf dem
                        erwarteten Niveau zu gewährleisten. Cookies sind kleine Dateien,
                        die auf Ihrem Computer gesendet werden und auf die später
                        zugegriffen werden kann. Sie können vorübergehend oder dauerhaft
                        sein. Dank Cookies können Sie unsere Websites problemlos
                        durchsuchen und auf die für Sie relevanten Informationen
                        zugreifen. Cookies zeigen uns, woran Sie und andere Besucher
                        unserer Websites interessiert sind, und auf diese Weise können
                        wir unsere Websites verbessern.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Wir verwenden Cookies, um Werbung im Auge zu behalten und
                        aggregierte Daten über Site-Traffic und Site-Interaktion zu
                        sammeln, damit wir in Zukunft bessere Site-Erfahrungen und
                        -Tools anbieten können.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Wir speichern und werten Informationen über Ihren letzten Besuch
                        auf unseren Websites und darüber aus, wie Sie verschiedene Teile
                        unserer Websites zu Analysezwecken verwendet haben, um zu
                        verstehen, wie Benutzer unsere Websites verwenden.
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                        8.1. Wir benützen auch:
                      </span>
                    </p>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt; text-decoration:underline">
                        8.1.2. Google Analytics
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        – Wie die meisten Websites verwendet diese Website Google
                        Analytics (GA), um die Benutzerinteraktion zu verfolgen. Wir
                        verwenden diese Daten, um die Anzahl der Personen zu bestimmen,
                        die unsere Website nutzen, um besser zu verstehen, wie sie
                      </span>
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        unsere Webseiten finden und nutzen, und um ihre Reise durch die
                        Website zu verfolgen.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Obwohl GA Daten wie Ihren geografischen Standort, Ihr Gerät,
                        Ihren Internetbrowser und Ihr Betriebssystem aufzeichnet, werden
                        Sie von keiner dieser Informationen für uns persönlich
                        identifiziert. GA zeichnet auch die IP-Adresse Ihres Computers
                        auf. Wir betrachten Google als Datenbearbeiter eines
                        Drittanbieters. Wenn Sie Cookies in Ihrem Internetbrowser
                        deaktivieren, kann GA keinen Teil Ihres Besuchs auf Seiten
                        dieser Website nachverfolgen.
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Wir verwenden eine Reihe von Drittensoftware, um
                        personenbezogene Daten in unserem Namen zu bearbeiten. Diese
                        wurden sorgfältig ausgewählt. Alle diese Drittanbieter haben
                        ihren Sitz in den USA und sind EU-US Privacy Shield-konform.
                      </span>
                    </p>
                    <ul type="disc" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:serif; font-size:10pt; margin:5pt 0pt 0pt 27.6pt; padding-left:8.4pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Google
                        </span>
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          
                          Ads
                        </span>
                      </li>
                      <li style=" color:#727272; font-family:serif; font-size:10pt; margin:5pt 0pt 0pt 27.6pt; padding-left:8.4pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Pipedrive
                        </span>
                      </li>
                    </ul>
                    <p style=" margin:5pt 0pt 0pt 36pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <ol start="9" type="1" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:Arial; font-size:12pt; margin:5pt 0pt 0pt 33.01pt; padding-left:2.99pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          Kontaktaufnahme mit uns
                        </span>
                      </li>
                    </ol>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können
                        Sie uns über die folgenden Informationen kontaktieren:
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        www.voltoo.de
                      </span>
                    </p>
                    <p style="font-size:10pt; line-height:108%; margin:0pt">
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Karlsruherstr.9
                      </span>
                    </p>
                    <p style="font-size:10pt; line-height:108%; margin:0pt">
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        70771 Leinfelden-Echterdingen
                      </span>
                    </p>
                    <p style="font-size:10pt; line-height:108%; margin:0pt">
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        Deutschland
                      </span>
                    </p>
                    <p style="font-size:10pt; line-height:108%; margin:0pt">
                      <span style="font-family:'等线 Light'; font-size:10pt">
                        &#xa0;
                      </span>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <a style="color:#0563c1" href="mailto:info@voltoo.de">
                        <span style="color:#0563c1; font-family:'等线 Light'; font-size:12pt; text-decoration:underline">
                          info@voltoo.de
                        </span>
                      </a>
                    </p>
                    <p style=" margin:0pt 0pt 7.5pt">
                      <span style="color:#727272; font-family:'等线 Light'; font-size:12pt">
                        &#xa0;
                      </span>
                    </p>
                    <ol start="10" type="1" style="margin:0pt; padding-left:0pt">
                      <li style=" color:#727272; font-family:Arial; font-size:12pt; margin:5pt 0pt 0pt 36pt; text-indent:0pt">
                        <span style="color:#727272; font-family:Arial; font-size:12pt; font-weight:bold">
                          ÄNDERUNGEN UNSERER DATENSCHUTZRICHTLINIEN
                        </span>
                      </li>
                    </ol>
                    <p style=" margin:0pt">
                      <span style="color:#727272; font-family:Arial; font-size:12pt">
                        Diese Datenschutzrichtlinie kann sich von Zeit zu Zeit ändern,
                        je nach Gesetzgebung oder Branchenentwicklung. Wir werden unsere
                        Kunden oder Website-Benutzer nicht ausdrücklich über diese
                        Änderungen informieren. Stattdessen empfehlen wir, diese Seite
                        gelegentlich auf Richtlinienänderungen zu überprüfen.
                      </span>
                    </p>
                  </div>
               
                    `,
          }}
        ></section>
      </div>
    )
  }
}
