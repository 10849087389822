import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
export default class CallAction extends Component {
  state = {
    title: 'test',
    description: 'test2',
    packageID: '',
  }
  render() {
    const { title, description } = this.props
    const { packageID } = this.state
    return (
      <section id="call-action" class="call-action-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="call-action-content mt-45">
                <h3 class="action-title">{title}</h3>
                <p class="text">{description}</p>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="call-action-form mt-50">
                <form action="#">
                  <input
                    type="text"
                    placeholder={packageID}
                    onChange={(e) => {
                      this.setState({
                        packageID: e.target.value,
                      })
                    }}
                  />
                  <div class="action-btn rounded-buttons">
                    <NavLink to={`/track?id=${packageID}`}>
                      <button class="main-btn rounded-three">Track</button>
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
