import React from 'react'

class Pricing extends React.Component {
  state = {}
  render() {
    const { title, subTitle, pricePackageArr, lang } = this.props
    return (
      <section id="pricing" class="pricing-area">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="section-title text-center pb-20">
                <h3 class="title">{title}</h3>
                <p class="text">{subTitle}</p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            {pricePackageArr.map((pricePackage, idx) => {
              const {
                icon,
                name,
                price,
                dateFormat,
                content,
                purchaseBtn = {},
              } = pricePackage
              return (
                <div class="col-lg-4 col-md-7 col-sm-9">
                  <div
                    class="pricing-style-one mt-40 wow fadeIn"
                    data-wow-duration="0s"
                    data-wow-delay={`0s`}
                  >
                    <div class="pricing-icon text-center">
                      <img src={icon} alt="" />
                    </div>
                    <div class="pricing-header text-center">
                      <h5 class="sub-title">{name}</h5>
                      <p class="month">
                        {lang !== 'cn' ? dateFormat : ''}
                        <span class="price">{price}</span>
                        {lang === 'cn' ? dateFormat : ''}
                      </p>
                    </div>
                    <div class="pricing-list">
                      <ul>
                        {content.map((item) => {
                          const { icon, content } = item
                          return (
                            <li>
                              <i class="lni-check-mark-circle"></i>
                              {content}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                    <div class="pricing-btn rounded-buttons text-center">
                      <a class="main-btn rounded-three" href={''}>
                        {purchaseBtn.name}
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

Pricing.defaultProps = {
  title: 'test',
  subTitle: 'test2',
  pricePackageArr: [
    {
      icon: '',
      name: '',
      price: '',
      content: [],
      purchaseBtn: {
        link: '',
        name: '购买',
      },
    },
  ],
}

export default Pricing
