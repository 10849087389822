import React from 'react'
import $ from 'jquery'

class SideBar extends React.Component {
  componentDidMount() {
    $('[anchor="#side-menu-right"], .overlay-right').on('click', function (
      event
    ) {
      $('.sidebar-right, .overlay-right').addClass('open')
    })

    $('[anchor="#close"], .overlay-right').on('click', function (event) {
      $('.sidebar-right, .overlay-right').removeClass('open')
    })
  }
  render() {
    const { lang } = this.props
    return (
      <div className="sidebar-right">
        <div className="sidebar-close">
          <span className="close" anchor="#close">
            <i className="lni-close"></i>
          </span>
        </div>
        <div className="sidebar-content">
          <div className="sidebar-logo text-center">
            <a href="#">
              <img src="./static/assets/images/logo.png" alt="Logo" />
            </a>
          </div>
          <div className="sidebar-menu">
            <ul>
              <li className="nav-item active">
                <a className="page-scroll" href="#home">
                  {lang === 'cn' ? '首页' : 'HOME'}
                </a>
              </li>
              <li className="nav-item">
                <a className="page-scroll" href="#about">
                  {lang === 'cn' ? '介绍' : 'ADVANTAGES'}
                </a>
              </li>
              {/* <li className="nav-item">
                        <a className="page-scroll" href="#portfolio">
                          PORTFOLIO
                        </a>
                      </li> */}
              <li className="nav-item">
                <a className="page-scroll" href="#pricing">
                  {lang === 'cn' ? '价格方案' : 'PRICING'}
                </a>
              </li>
              <li className="nav-item">
                <a className="page-scroll" href="#call-action">
                  {lang === 'cn' ? '物流追踪' : 'TRACKING'}
                </a>
              </li>
              <li className="nav-item">
                <a className="page-scroll" href="#testimonial">
                  {lang === 'cn' ? '客户' : 'CLIENTS'}
                </a>
              </li>
              <li className="nav-item">
                <a className="page-scroll" href="#contact">
                  {lang === 'cn' ? '联系我们' : 'CONTACT'}
                </a>
              </li>
              {/* <li>
                <a href="#">ABOUT</a>
              </li>
              <li>
                <a href="#">SERVICES</a>
              </li>
              <li>
                <a href="#">RESOURCES</a>
              </li>
              <li>
                <a href="#">CONTACT</a>
              </li> */}
            </ul>
          </div>
          {/* <div className="sidebar-social d-flex align-items-center justify-content-center"> 
            <span>FOLLOW US</span>
            <ul>
              <li>
                <a href="#">
                  <i className="lni-twitter-original"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="lni-facebook-filled"></i>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    )
  }
}

export default SideBar
