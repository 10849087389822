import React from 'react'

class CarouselItem extends React.Component {
    render() {
        const { backgroundImage, title,active } = this.props
        console.log(backgroundImage)
        return (
            <div
                className={`carousel-item bg_cover ${active ? 'active': ''} ` }
                style={{
                    backgroundImage
                } }>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-7 col-sm-10">
                                <h2 className="carousel-title">
                                    {
                                        title
                                    }
                                    {/* Refreshing Design & Easy to Customize */}
                    </h2>
                                <ul className="carousel-btn rounded-buttons">
                                    {/* <li>
                                        <a className="main-btn rounded-three" href="#">
                                            GET STARTED
                        </a>
                                    </li>
                                    <li>
                                        <a className="main-btn rounded-one" href="#">
                                            DOWNLOAD
                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default CarouselItem