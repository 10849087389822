import React from 'react'


export default class Client extends React.Component {
    render() {
        return (
            <section id="client" class="client-logo-area">
                <div class="container">
                    <div class="row client-active">
                        <div class="col-lg-3">
                            <div class="single-client text-center">
                                <img src="./static/assets/images/client_logo_01.png" alt="Logo" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="single-client text-center">
                                <img src="./static/assets/images/client_logo_02.png" alt="Logo" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="single-client text-center">
                                <img src="./static/assets/images/client_logo_03.png" alt="Logo" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="single-client text-center">
                                <img src="./static/assets/images/client_logo_04.png" alt="Logo" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="single-client text-center">
                                <img src="./static/assets/images/client_logo_05.png" alt="Logo" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="single-client text-center">
                                <img src="./static/assets/images/client_logo_06.png" alt="Logo" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="single-client text-center">
                                <img src="./static/assets/images/client_logo_07.png" alt="Logo" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="single-client text-center">
                                <img src="./static/assets/images/client_logo_08.png" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}