import React, { Component } from 'react'

export default class Foot extends Component {
    render() {
        return (
            <footer id="footer" class="footer-area">
                <div class="footer-widget">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6">
                                <div class="footer-link">
                                    <h6 class="footer-title">Company</h6>
                                    <ul>
                                        <li>
                                            <a href="#">About</a>
                                        </li>
                                        <li>
                                            <a href="#">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="footer-link">
                                    <h6 class="footer-title">Solutions</h6>
                                    <ul>
                                        <li>
                                            <a href="#">Facilities Services</a>
                                        </li>
                                        <li>
                                            <a href="#">Workplace Staffing</a>
                                        </li>
                                        <li>
                                            <a href="#">Project Management</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="footer-link">
                                    <h6 class="footer-title">Product & Services</h6>
                                    <ul>
                                        <li>
                                            <a href="#">Products</a>
                                        </li>
                                        <li>
                                            <a href="#">Business</a>
                                        </li>
                                        <li>
                                            <a href="#">Developer</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="footer-link">
                                    <h6 class="footer-title">Help & Suuport</h6>
                                    <ul>
                                        <li>
                                            <a href="#">Support Center</a>
                                        </li>
                                        <li>
                                            <a href="#">FAQ</a>
                                        </li>
                                        <li>
                                            <a href="#">Terms & Conditions</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-copyright">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="copyright text-center text-lg-left mt-10">
                                    <p class="text">
                                        Copyright &copy; 2019.Company name All rights reserved.
                        <a target="_blank" href="http://sc.chinaz.com/moban/">&#x7F51;&#x9875;&#x6A21;&#x677F;</a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="footer-logo text-center mt-10">
                                    <a href="index.html">
                                        <img src="./static/assets/images/logo-2.svg" alt="Logo" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <ul class="social text-center text-lg-right mt-10">
                                    <li>
                                        <a href="#">
                                            <i class="lni-facebook-filled"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="lni-twitter-original"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="lni-instagram-original"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="lni-linkedin-original"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
