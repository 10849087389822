import React, { Component } from 'react'

export default class Contact extends Component {
  state = {}

  render() {
    const {
      title,
      subTitle,
      summary,
      description,
      place,
      phone,
      phone2,
      email,
    } = this.props
    return (
      <section id="contact" class="contact-area">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="section-title text-center pb-20">
                <h3 class="title">{title}</h3>
                <p class="text">{subTitle}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div
                class="contact-two mt-50 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.2s"
              >
                <h4 class="contact-title">{summary}</h4>
                <p class="text">{description}</p>
                <ul class="contact-info">
                  <li>
                    <i class="lni-money-location"></i>
                    <span>
                      {place && place.toString().split('\\n').join('\n')}
                    </span>
                  </li>
                  <li>
                    <i class="lni-phone-handset"></i> {phone}
                  </li>
                  {phone2 && (
                    <li>
                      <i class="lni-phone-handset"></i> {phone2}
                    </li>
                  )}

                  <li>
                    <i class="lni-envelope"></i> {email}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="contact-form form-style-one mt-35 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <form
                  id="contact-form"
                  action="https://voltoo.net/index/contact/"
                  method="post"
                >
                  <div class="form-input mt-15">
                    <label>Name</label>
                    <div class="input-items default">
                      <input type="text" placeholder="Name" name="name" />
                      <i class="lni-user"></i>
                    </div>
                  </div>
                  <div class="form-input mt-15">
                    <label>Email/phone</label>
                    <div class="input-items default">
                      <input
                        type="email"
                        placeholder="Email"
                        name="contact_info"
                      />
                      <i class="lni-envelope"></i>
                    </div>
                  </div>
                  <div class="form-input mt-15">
                    <label>Massage</label>
                    <div class="input-items default">
                      <textarea placeholder="Massage" name="content"></textarea>
                      <i class="lni-pencil-alt"></i>
                    </div>
                  </div>
                  <p class="form-message"></p>
                  <div class="form-input rounded-buttons mt-20">
                    <button type="submit" class="main-btn rounded-three">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

Contact.defaultProps = {
  title: '',
  subTitle: '',
  summary: '',
  description: '',
  example: [
    {
      place: '',
      phone: '',
      phone2: '',
      email: '',
    },
  ],
}
// +49 7118 8829 918
