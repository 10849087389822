import React from 'react'
import { NavLink } from 'react-router-dom'
import './Recordnumber.css'
class Recordnumber extends React.Component {
  render() {
    const { lang } = this.props
    let data = '版权声明'
    let privacy = '隐私声明'
    switch (lang) {
      case 'de':
        data = 'Impressum'
        privacy = 'Datenschutz'
        break
      case 'en':
        data = 'Impressum'
        privacy = 'privacy'
        break
      default:
        data = '版权声明'
        privacy = '隐私声明'
    }
    return (
      <div className={'record-number'}>
        <NavLink to={`/impressum`}>{data}</NavLink>
        <NavLink to={`/datenschutz`}>{privacy}</NavLink>
      </div>
    )
  }
}

export default Recordnumber
